<template>
  <div>
    <div v-if="logTypeColors.length" class="current-trip-report d-flex align-center justify-space-between">
      <h3 class="mb-2">Viewing trip {{ currentTripId }}</h3>
      <v-btn v-show="currentTripId" icon small class="cursor-pointer" @click="clearDataByEventId()">
        <v-icon>{{ mdiClose }}</v-icon>
      </v-btn>
    </div>
    <div class="map__container">
      <div class="map__loading">Loading...</div>
      <div id="map" class="map"></div>
    </div>
    <div v-show="logTypeColors.length" class="mt-2">
      <h3 class="mb-2">Color Coding for Log Types</h3>
      <v-row>
        <v-col v-for="logTypeColor in logTypeColors" :key="logTypeColor.type" cols="4" class="d-flex align-center pa-2">
          <span class="logTypeColor__color" :style="{ backgroundColor: logTypeColor.color }"></span>
          {{ logTypeColor.type }}
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import { mdiClose } from "@mdi/js"

import themeConfig from "@themeConfig"

export default {
  name: "VehicleGPSHistoryMap",
  props: {
    gpsData: {
      type: Array,
      default: () => [],
      required: false,
    },
    dataByEventId: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  data() {
    return {
      map: null,
      currentMarkers: [],
      currentLogType: [],
    }
  },
  setup() {
    return {
      mdiClose,
    }
  },
  computed: {
    logTypeColors() {
      return this.currentLogType.map(logType => ({
        type: logType,
        color: this.getColor(logType),
      }))
    },

    currentTripId() {
      return this.dataByEventId[0]?.event_data?.trip_id
    },

    filteredGpsData() {
      if (this.currentTripId) {
        return this.gpsData.filter(data => data.trip_id === this.currentTripId)
      }

      return this.gpsData
    },
  },
  watch: {
    dataByEventId() {
      const features = []

      // remove current markers
      this.currentMarkers.forEach(marker => marker.remove())

      this.dataByEventId.map(data => {
        const points = data?.event_data.logs || []

        points.forEach(item => {
          const description = `
            <div class="mapboxgl-popup-content-desc">
              <p>Log Type: ${item.log_type}</p>
              <p>Stop ID: ${item.stop_id}</p>
              <p>Timestamp: ${item.timestamp}</p>
              <p>Location: ${item.location.lat}, ${item.location.lon}</p>
            </div>
          `

          if (!this.currentLogType.includes(item.log_type)) {
            this.currentLogType.push(item.log_type)
          }

          features.push({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [item.location.lon, item.location.lat],
            },
            properties: {
              title: item.log_type,
              description,
              color: this.getColor(item.log_type),
              iconSize: [15, 15],
            },
          })
        })

        return features
      })

      const geoJson = {
        type: "FeatureCollection",
        features,
      }

      geoJson.features.forEach(marker => {
        this.createSquareMarker(marker)
      })

      this.updateVehicleGPSLayer()
    },

    gpsData() {
      this.updateVehicleGPSLayer()
    },
  },

  mounted() {
    mapboxgl.accessToken = this.$config.mapbox.accessToken
    this.map = new mapboxgl.Map({
      container: "map",
      style: this.$config.mapbox.style,
      center: [174.779, -41.28988],
      zoom: 14,
    })
  },

  beforeDestroy() {
    this.map.remove()
  },

  methods: {
    createSquareMarker({ geometry: { coordinates }, properties: { description, color, iconSize } }) {
      const el = document.createElement("div")
      el.className = "marker"
      el.style.backgroundColor = color
      el.style.width = `${iconSize[0]}px`
      el.style.height = `${iconSize[1]}px`

      const marker = new mapboxgl.Marker(el)
        .setLngLat(coordinates)
        .setPopup(
          new mapboxgl.Popup({ offset: 25 }) // add popups
            .setHTML(description),
        )
        .addTo(this.map)

      this.currentMarkers.push(marker)
    },

    getColor(logType) {
      const { logTypeColors } = themeConfig

      if (logTypeColors[logType]) {
        return logTypeColors[logType]
      }
      console.error("Unknown log type:", logType)

      return logTypeColors.unknown
    },

    clearDataByEventId() {
      this.currentMarkers.forEach(marker => marker.remove())
      this.currentLogType = []
      this.$emit("update:dataByEventId", [])
    },

    updateVehicleGPSLayer() {
      const routeSourceId = "route"
      const offrouteSourceId = "offRoutes"
      const routeLayerId = "route"
      const offroutesLayerId = "OffRoutes"
      const directionsLayerId = "directions"

      const featureLineString = []
      const featureCollectionOffRoutes = []
      const offRoutes = []
      const GPSTraces = this.filteredGpsData

      if (this.map.getLayer(routeLayerId)) {
        this.map.removeLayer(routeLayerId)
      }
      if (this.map.getLayer(offroutesLayerId)) {
        this.map.removeLayer(offroutesLayerId)
      }
      if (this.map.getLayer(directionsLayerId)) {
        this.map.removeLayer(directionsLayerId)
      }

      // Create a "LngLatBounds" with both corners at the first coordinate.
      const bounds = this.gpsData.length
        ? new mapboxgl.LngLatBounds(
            [GPSTraces[0].longitude, GPSTraces[0].latitude],
            [GPSTraces[0].longitude, GPSTraces[0].latitude],
          )
        : null

      GPSTraces.forEach((GPSTrace, index) => {
        if (GPSTrace.latitude !== 0 && GPSTrace.longitude !== 0) {
          featureLineString.push([GPSTrace.longitude, GPSTrace.latitude])

          // Extend the "LngLatBounds" to include every 5th coordinate in the bounds result.
          if (index % 5 === 0) {
            bounds.extend([GPSTrace.longitude, GPSTrace.latitude])
          }
        }
      })

      offRoutes.forEach(offRoute => {
        featureCollectionOffRoutes.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [offRoute.long, offRoute.lat],
          },
          properties: {
            trip_id: offRoute.trip_id,
          },
        })
      })

      if (this.map.getSource(routeSourceId)) {
        this.map.removeSource(routeSourceId)
      }
      if (this.map.getSource(offrouteSourceId)) {
        this.map.removeSource(offrouteSourceId)
      }

      this.map.addSource(routeSourceId, {
        type: "geojson",
        data: {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: featureLineString,
          },
        },
      })

      this.map.addSource(offrouteSourceId, {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: featureCollectionOffRoutes,
        },
      })

      this.map.addLayer({
        id: routeLayerId,
        type: "line",
        source: "route",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "#ec343a",
          "line-width": 1,
        },
      })

      this.map.addLayer({
        id: offroutesLayerId,
        type: "symbol",
        source: "offRoutes",
        layout: {
          visibility: "visible",
          "icon-image": "poi-marker",
          "icon-allow-overlap": true,
          "text-field": "{trip_id}",
          "text-allow-overlap": true,
          "text-ignore-placement": true,
          "text-size": 11,
          "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
          "text-offset": [0, 0.6],
          "text-anchor": "top",
        },
        paint: {
          "text-color": "#aa0000",
        },
      })

      this.map.addLayer({
        id: directionsLayerId,
        type: "symbol",
        source: "route",
        paint: {},
        layout: {
          "symbol-placement": "line",
          "symbol-spacing": 50,
          "icon-image": "",
          "icon-size": 0.35,
          "icon-rotate": 90,
          "icon-rotation-alignment": "map",
          "icon-allow-overlap": true,
          "icon-ignore-placement": true,
        },
      })

      if (bounds) {
        this.map.fitBounds(bounds, {
          padding: 50,
        })
      }
    },
  },
}
</script>

<style lang="scss">
$popup-padding: 11px;
$color-red: #ff4100;
$tip-color: #ffffff;
$popup-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

.map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;

  &__container {
    margin: 0;
    padding: 0;
    height: 25rem;
    position: relative;
  }
}

.logTypeColor__color {
  width: 1rem;
  height: 1rem;
  margin-right: 5px;
}

.mapboxgl-popup {
  .mapboxgl-popup-content {
    border-radius: 10px;
    padding: $popup-padding;
    box-shadow: $popup-shadow;
    background-color: $tip-color;

    &-desc {
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 21px;

      p {
        margin: 0;
      }
    }
  }

  &-close-button {
    width: 20px;
    border: none;
    border-radius: 0 10px 0 10px;
    font-size: 20px;
    padding: 5px;

    &:hover {
      background-color: $color-red;
      color: white;
    }
  }
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  border-bottom-color: $tip-color;
}

.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip {
  border-bottom-color: $tip-color;
}

.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: $tip-color;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: $tip-color;
}

.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip {
  border-top-color: $tip-color;
}

.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: $tip-color;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: $tip-color;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: $tip-color;
}
</style>
