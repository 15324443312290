<template>
  <div class="marker">
    <div
      v-if="route"
      :style="`--route-color: #${route.route_color}; --route-text-color: #${route.route_text_color};`"
      class="vehicle-marker"
    >
      <span class="vehicle-marker__route-no">{{ route.route_short_name }} </span>
      <span
        v-if="position.bearing"
        class="vehicle-marker__direction"
        :style="`--rotation: ${position.bearing}deg;`"
      ></span>
    </div>
    <div v-else :style="`--route-color: ${color}; --route-text-color: #ffffff;`" class="vehicle-marker">
      <span
        v-if="position.bearing"
        class="vehicle-marker__direction"
        :style="`--rotation: ${position.bearing}deg;`"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "VehicleMarker",
  props: {
    route: {
      type: Object,
      required: false,
    },
    position: {
      type: Object,
      default: () => {},
    },
    vehicleData: {
      type: Object,
      required: true,
    },
  },

  computed: {
    color() {
      const { status, config, rtData } = this.vehicleData
      const tripIdMatch = status.trip.trip_id === rtData?.trip.trip_id

      // If online (not offline - includes idle and updating)
      if (status.status !== "offline") {
        if (!status.trip.trip_id && !rtData?.trip.trip_id) {
          return "var(--marker-blue-grey)" // - online with no trip assigned on device and no trip in rt feed
        }
        if (config.is_enabled === true) {
          if (tripIdMatch) {
            return "var(--marker-green)" // - online, enabled and trip_id match
          }

          return "var(--marker-red)" // - online, enabled and trip_id mismatch
        }
        if (tripIdMatch) {
          return "var(--marker-light-green)" // - online, not enabled and trip_id match
        }

        return "var(--marker-light-red)" // - online, not enabled and trip_id mismatch
      }

      return "var(--marker-grey)" // - offline
    },
  },

  watch: {
    vehicleData(vehicle) {
      this.vehicle = vehicle
    },
  },
}
</script>

<style lang="scss" scoped>
@mixin triangle($size, $color) {
  border-left: $size solid transparent;
  border-right: $size solid transparent;
  border-bottom: $size solid $color;
  width: 0;
  height: 0;
}

.marker {
  border: 1px red !important;
  &:hover,
  &[data-is-active] {
    z-index: 1;
  }
}

.vehicle-marker {
  background-color: var(--route-color);
  color: var(--route-text-color);
  font-weight: bold;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  position: relative;
  border: 5px solid var(--route-color);
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    border: 2px solid white;
    box-sizing: content-box;
    border-radius: 50%;
  }

  &--active,
  &:hover:not(.vehicle-marker--static) {
    cursor: pointer;
    transform: scale(1.2);
  }

  &--static {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
  }

  &--highlighted {
    box-shadow: 0px 0px 12px 7px black;
  }

  &__direction {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    transform: rotate(var(--rotation));

    &:before {
      content: "";
      @include triangle(11px, var(--route-color));
      position: absolute;
      top: -11px;
      left: 50%;
      transform: translateX(-50%);
    }
    // outer triangle
    &:after {
      content: "";
      @include triangle(8px, white);
      position: absolute;
      top: -7px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>
